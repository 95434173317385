<div class="dashboard workflow">
    <aside class="sidebar">
        <project-wizard-sidebar></project-wizard-sidebar>
    </aside>
    <main class="main">
        <div class="outlet-container">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
