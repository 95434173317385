/**
 * Neptune.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class TreatmentBMPUpsertDto { 
    TreatmentBMPID?: number;
    TreatmentBMPName: string;
    TreatmentBMPTypeID: number;
    TreatmentBMPTypeName?: string;
    TreatmentBMPModelingTypeID?: number;
    StormwaterJurisdictionName?: string;
    WatershedName?: string;
    Longitude: number;
    Latitude: number;
    Notes?: string;
    AverageDivertedFlowrate?: number;
    AverageTreatmentFlowrate?: number;
    DesignDryWeatherTreatmentCapacity?: number;
    DesignLowFlowDiversionCapacity?: number;
    DesignMediaFiltrationRate?: number;
    DesignResidenceTimeforPermanentPool?: number;
    DiversionRate?: number;
    DrawdownTimeForWQDetentionVolume?: number;
    EffectiveFootprint?: number;
    EffectiveRetentionDepth?: number;
    InfiltrationDischargeRate?: number;
    InfiltrationSurfaceArea?: number;
    MediaBedFootprint?: number;
    PermanentPoolOrWetlandVolume?: number;
    StorageVolumeBelowLowestOutletElevation?: number;
    SummerHarvestedWaterDemand?: number;
    DrawdownTimeForDetentionVolume?: number;
    TotalEffectiveBMPVolume?: number;
    TotalEffectiveDrywellBMPVolume?: number;
    TreatmentRate?: number;
    UnderlyingInfiltrationRate?: number;
    WaterQualityDetentionVolume?: number;
    WettedFootprint?: number;
    WinterHarvestedWaterDemand?: number;
    RoutingConfigurationID?: number;
    TimeOfConcentrationID?: number;
    UnderlyingHydrologicSoilGroupID?: number;
    MonthsOfOperationID?: number;
    DryWeatherFlowOverrideID?: number;
    AreAllModelingAttributesComplete?: boolean;
    IsFullyParameterized?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface TreatmentBMPUpsertDtoForm { 
    TreatmentBMPID?: FormControl<number>;
    TreatmentBMPName: FormControl<string>;
    TreatmentBMPTypeID: FormControl<number>;
    TreatmentBMPTypeName?: FormControl<string>;
    TreatmentBMPModelingTypeID?: FormControl<number>;
    StormwaterJurisdictionName?: FormControl<string>;
    WatershedName?: FormControl<string>;
    Longitude: FormControl<number>;
    Latitude: FormControl<number>;
    Notes?: FormControl<string>;
    AverageDivertedFlowrate?: FormControl<number>;
    AverageTreatmentFlowrate?: FormControl<number>;
    DesignDryWeatherTreatmentCapacity?: FormControl<number>;
    DesignLowFlowDiversionCapacity?: FormControl<number>;
    DesignMediaFiltrationRate?: FormControl<number>;
    DesignResidenceTimeforPermanentPool?: FormControl<number>;
    DiversionRate?: FormControl<number>;
    DrawdownTimeForWQDetentionVolume?: FormControl<number>;
    EffectiveFootprint?: FormControl<number>;
    EffectiveRetentionDepth?: FormControl<number>;
    InfiltrationDischargeRate?: FormControl<number>;
    InfiltrationSurfaceArea?: FormControl<number>;
    MediaBedFootprint?: FormControl<number>;
    PermanentPoolOrWetlandVolume?: FormControl<number>;
    StorageVolumeBelowLowestOutletElevation?: FormControl<number>;
    SummerHarvestedWaterDemand?: FormControl<number>;
    DrawdownTimeForDetentionVolume?: FormControl<number>;
    TotalEffectiveBMPVolume?: FormControl<number>;
    TotalEffectiveDrywellBMPVolume?: FormControl<number>;
    TreatmentRate?: FormControl<number>;
    UnderlyingInfiltrationRate?: FormControl<number>;
    WaterQualityDetentionVolume?: FormControl<number>;
    WettedFootprint?: FormControl<number>;
    WinterHarvestedWaterDemand?: FormControl<number>;
    RoutingConfigurationID?: FormControl<number>;
    TimeOfConcentrationID?: FormControl<number>;
    UnderlyingHydrologicSoilGroupID?: FormControl<number>;
    MonthsOfOperationID?: FormControl<number>;
    DryWeatherFlowOverrideID?: FormControl<number>;
    AreAllModelingAttributesComplete?: FormControl<boolean>;
    IsFullyParameterized?: FormControl<boolean>;
}

export class TreatmentBMPUpsertDtoFormControls { 
    public static TreatmentBMPID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TreatmentBMPName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static TreatmentBMPTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static TreatmentBMPTypeName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TreatmentBMPModelingTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static StormwaterJurisdictionName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WatershedName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Longitude = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static Latitude = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static Notes = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageDivertedFlowrate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageTreatmentFlowrate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignDryWeatherTreatmentCapacity = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignLowFlowDiversionCapacity = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignMediaFiltrationRate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignResidenceTimeforPermanentPool = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DiversionRate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DrawdownTimeForWQDetentionVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static EffectiveFootprint = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static EffectiveRetentionDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static InfiltrationDischargeRate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static InfiltrationSurfaceArea = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MediaBedFootprint = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PermanentPoolOrWetlandVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static StorageVolumeBelowLowestOutletElevation = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerHarvestedWaterDemand = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DrawdownTimeForDetentionVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalEffectiveBMPVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalEffectiveDrywellBMPVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TreatmentRate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static UnderlyingInfiltrationRate = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterQualityDetentionVolume = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WettedFootprint = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterHarvestedWaterDemand = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static RoutingConfigurationID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TimeOfConcentrationID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static UnderlyingHydrologicSoilGroupID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MonthsOfOperationID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherFlowOverrideID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AreAllModelingAttributesComplete = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static IsFullyParameterized = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
