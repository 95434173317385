<page-header [customRichTextTypeID]="richTextTypeID" pageTitle="Labels and Definitions"></page-header>
<app-alert-display></app-alert-display>

<div class="page-body">
    <neptune-grid
        height="800px"
        [rowData]="fieldDefinitions"
        [columnDefs]="columnDefs"
        downloadFileName="field-definitions"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"></neptune-grid>
</div>
