<section class="hero">
    <div class="hero__photo">
        <img src="/assets/main/home_image.png" />
    </div>
    <div class="hero__actions card">
        <ng-container *ngIf="currentUser$ | async as currentUser; else notSignedIn">
            <div class="card-header"><h3 class="card-title">Quick Actions</h3></div>
            <div class="card-body">
                <div class="hero__btns">
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [routerLink]="['/planning/projects']">View Projects</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [routerLink]="['/planning/projects/new']">Add a Project</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [routerLink]="['/planning/planning-map']">Planning Map</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" href="{{ ocstBaseUrl() }}">OCST Home</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" href="{{ requestSupportUrl() }}">Request Support</a>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #notSignedIn>
            <div class="card-header"><h3 class="card-title">Welcome.</h3></div>
            <div class="card-body">
                <p class="mb-2">Log in to view your Account. Create an Account if you don't have one yet.</p>
                <div class="hero__btns">
                    <div class="hero__btns-wrapper">
                        <a (click)="login()" class="btn btn-primary">Login</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a (click)="createAccount()" class="btn btn-primary">Create Account</a>
                    </div>
                </div>
                <hr />
                <div>
                    <small style="font-size: 11px">
                        Need help logging in? <br />
                        <a href="{{ forgotPasswordUrl() }}">Forgot Password</a> | <a href="{{ forgotUsernameUrl() }}">Forgot Username</a> |
                        <a href="{{ keystoneSupportUrl() }}">Request Support</a>
                    </small>
                </div>
            </div>
        </ng-template>
    </div>
</section>
<app-alert-display></app-alert-display>
<h1 class="text-primary mt-4 mb-4">OCST Planning Module</h1>
<ng-container *ngIf="currentUser$ | async as currentUser">
    <div *ngIf="userIsUnassigned(currentUser)" class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            <span *ngIf="userIsOCTAGrantReviewer(currentUser); else unassignedUserMessage">
                Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, and an administrator has granted you
                read-only access to Review Grant Programs. You may <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or
                comments.
            </span>
            <ng-template #unassignedUserMessage>
                <span>
                    Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, but your account is not yet
                    configured. You will receive an email from the Administrators when your account is ready to use. You may
                    <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or comments.
                </span>
            </ng-template>
        </div>
    </div>
</ng-container>

<custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
