<!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
<router-outlet *ngIf="!isIframe"></router-outlet>

<footer class="site-footer grid-12">
    <div class="credits copy copy-3">
        This program is free software; you can redistribute it and/or modify it under the terms of the
        <a href="https://www.gnu.org/licenses/agpl-3.0.html">GNU Affero General Public License</a> as published by the <a href="https://www.fsf.org/">Free Software Foundation</a>.
        Disclaimers, copyright, and source code are available <a href="{{ legalUrl() }}">here</a>.
        <div class="col text-center">Designed, Built, and Maintained by <a href="https://esassoc.com/" target="_blank">Environmental Science Associates</a>.</div>
    </div>
    <!-- <div class="system-info" *ngIf="systemInfo$ | async as systemInfo">
        <span class="version" [title]="systemInfo.FullInformationalVersion"> Version: {{ systemInfo.Version }} - {{ systemInfo.Environment }} </span>
        <span class="version">Built on: {{ systemInfo.CompilationDateTime | date: "short" }}</span>
    </div> -->
</footer>
