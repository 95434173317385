/**
 * Neptune.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ProjectLoadReducingResultDto { 
    TreatmentBMPID?: number;
    TreatmentBMPName?: string;
    ProjectID?: number;
    ProjectName?: string;
    EffectiveAreaAcres?: number;
    DesignStormDepth85thPercentile?: number;
    DesignVolume85thPercentile?: number;
    WetWeatherInflow?: number;
    WetWeatherTreated?: number;
    WetWeatherRetained?: number;
    WetWeatherUntreated?: number;
    WetWeatherTSSReduced?: number;
    WetWeatherTNReduced?: number;
    WetWeatherTPReduced?: number;
    WetWeatherFCReduced?: number;
    WetWeatherTCuReduced?: number;
    WetWeatherTPbReduced?: number;
    WetWeatherTZnReduced?: number;
    WetWeatherTSSInflow?: number;
    WetWeatherTNInflow?: number;
    WetWeatherTPInflow?: number;
    WetWeatherFCInflow?: number;
    WetWeatherTCuInflow?: number;
    WetWeatherTPbInflow?: number;
    WetWeatherTZnInflow?: number;
    SummerDryWeatherInflow?: number;
    SummerDryWeatherTreated?: number;
    SummerDryWeatherRetained?: number;
    SummerDryWeatherUntreated?: number;
    SummerDryWeatherTSSReduced?: number;
    SummerDryWeatherTNReduced?: number;
    SummerDryWeatherTPReduced?: number;
    SummerDryWeatherFCReduced?: number;
    SummerDryWeatherTCuReduced?: number;
    SummerDryWeatherTPbReduced?: number;
    SummerDryWeatherTZnReduced?: number;
    SummerDryWeatherTSSInflow?: number;
    SummerDryWeatherTNInflow?: number;
    SummerDryWeatherTPInflow?: number;
    SummerDryWeatherFCInflow?: number;
    SummerDryWeatherTCuInflow?: number;
    SummerDryWeatherTPbInflow?: number;
    SummerDryWeatherTZnInflow?: number;
    WinterDryWeatherInflow?: number;
    WinterDryWeatherTreated?: number;
    WinterDryWeatherRetained?: number;
    WinterDryWeatherUntreated?: number;
    WinterDryWeatherTSSReduced?: number;
    WinterDryWeatherTNReduced?: number;
    WinterDryWeatherTPReduced?: number;
    WinterDryWeatherFCReduced?: number;
    WinterDryWeatherTCuReduced?: number;
    WinterDryWeatherTPbReduced?: number;
    WinterDryWeatherTZnReduced?: number;
    WinterDryWeatherTSSInflow?: number;
    WinterDryWeatherTNInflow?: number;
    WinterDryWeatherTPInflow?: number;
    WinterDryWeatherFCInflow?: number;
    WinterDryWeatherTCuInflow?: number;
    WinterDryWeatherTPbInflow?: number;
    WinterDryWeatherTZnInflow?: number;
    DryWeatherInflow?: number;
    DryWeatherTreated?: number;
    DryWeatherRetained?: number;
    DryWeatherUntreated?: number;
    DryWeatherTSSReduced?: number;
    DryWeatherTNReduced?: number;
    DryWeatherTPReduced?: number;
    DryWeatherFCReduced?: number;
    DryWeatherTCuReduced?: number;
    DryWeatherTPbReduced?: number;
    DryWeatherTZnReduced?: number;
    DryWeatherTSSInflow?: number;
    DryWeatherTNInflow?: number;
    DryWeatherTPInflow?: number;
    DryWeatherFCInflow?: number;
    DryWeatherTCuInflow?: number;
    DryWeatherTPbInflow?: number;
    DryWeatherTZnInflow?: number;
    TotalInflow?: number;
    TotalTreated?: number;
    TotalRetained?: number;
    TotalUntreated?: number;
    TotalTSSReduced?: number;
    TotalTNReduced?: number;
    TotalTPReduced?: number;
    TotalFCReduced?: number;
    TotalTCuReduced?: number;
    TotalTPbReduced?: number;
    TotalTZnReduced?: number;
    TotalTSSInflow?: number;
    TotalTNInflow?: number;
    TotalTPInflow?: number;
    TotalFCInflow?: number;
    TotalTCuInflow?: number;
    TotalTPbInflow?: number;
    TotalTZnInflow?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ProjectLoadReducingResultDtoForm { 
    TreatmentBMPID?: FormControl<number>;
    TreatmentBMPName?: FormControl<string>;
    ProjectID?: FormControl<number>;
    ProjectName?: FormControl<string>;
    EffectiveAreaAcres?: FormControl<number>;
    DesignStormDepth85thPercentile?: FormControl<number>;
    DesignVolume85thPercentile?: FormControl<number>;
    WetWeatherInflow?: FormControl<number>;
    WetWeatherTreated?: FormControl<number>;
    WetWeatherRetained?: FormControl<number>;
    WetWeatherUntreated?: FormControl<number>;
    WetWeatherTSSReduced?: FormControl<number>;
    WetWeatherTNReduced?: FormControl<number>;
    WetWeatherTPReduced?: FormControl<number>;
    WetWeatherFCReduced?: FormControl<number>;
    WetWeatherTCuReduced?: FormControl<number>;
    WetWeatherTPbReduced?: FormControl<number>;
    WetWeatherTZnReduced?: FormControl<number>;
    WetWeatherTSSInflow?: FormControl<number>;
    WetWeatherTNInflow?: FormControl<number>;
    WetWeatherTPInflow?: FormControl<number>;
    WetWeatherFCInflow?: FormControl<number>;
    WetWeatherTCuInflow?: FormControl<number>;
    WetWeatherTPbInflow?: FormControl<number>;
    WetWeatherTZnInflow?: FormControl<number>;
    SummerDryWeatherInflow?: FormControl<number>;
    SummerDryWeatherTreated?: FormControl<number>;
    SummerDryWeatherRetained?: FormControl<number>;
    SummerDryWeatherUntreated?: FormControl<number>;
    SummerDryWeatherTSSReduced?: FormControl<number>;
    SummerDryWeatherTNReduced?: FormControl<number>;
    SummerDryWeatherTPReduced?: FormControl<number>;
    SummerDryWeatherFCReduced?: FormControl<number>;
    SummerDryWeatherTCuReduced?: FormControl<number>;
    SummerDryWeatherTPbReduced?: FormControl<number>;
    SummerDryWeatherTZnReduced?: FormControl<number>;
    SummerDryWeatherTSSInflow?: FormControl<number>;
    SummerDryWeatherTNInflow?: FormControl<number>;
    SummerDryWeatherTPInflow?: FormControl<number>;
    SummerDryWeatherFCInflow?: FormControl<number>;
    SummerDryWeatherTCuInflow?: FormControl<number>;
    SummerDryWeatherTPbInflow?: FormControl<number>;
    SummerDryWeatherTZnInflow?: FormControl<number>;
    WinterDryWeatherInflow?: FormControl<number>;
    WinterDryWeatherTreated?: FormControl<number>;
    WinterDryWeatherRetained?: FormControl<number>;
    WinterDryWeatherUntreated?: FormControl<number>;
    WinterDryWeatherTSSReduced?: FormControl<number>;
    WinterDryWeatherTNReduced?: FormControl<number>;
    WinterDryWeatherTPReduced?: FormControl<number>;
    WinterDryWeatherFCReduced?: FormControl<number>;
    WinterDryWeatherTCuReduced?: FormControl<number>;
    WinterDryWeatherTPbReduced?: FormControl<number>;
    WinterDryWeatherTZnReduced?: FormControl<number>;
    WinterDryWeatherTSSInflow?: FormControl<number>;
    WinterDryWeatherTNInflow?: FormControl<number>;
    WinterDryWeatherTPInflow?: FormControl<number>;
    WinterDryWeatherFCInflow?: FormControl<number>;
    WinterDryWeatherTCuInflow?: FormControl<number>;
    WinterDryWeatherTPbInflow?: FormControl<number>;
    WinterDryWeatherTZnInflow?: FormControl<number>;
    DryWeatherInflow?: FormControl<number>;
    DryWeatherTreated?: FormControl<number>;
    DryWeatherRetained?: FormControl<number>;
    DryWeatherUntreated?: FormControl<number>;
    DryWeatherTSSReduced?: FormControl<number>;
    DryWeatherTNReduced?: FormControl<number>;
    DryWeatherTPReduced?: FormControl<number>;
    DryWeatherFCReduced?: FormControl<number>;
    DryWeatherTCuReduced?: FormControl<number>;
    DryWeatherTPbReduced?: FormControl<number>;
    DryWeatherTZnReduced?: FormControl<number>;
    DryWeatherTSSInflow?: FormControl<number>;
    DryWeatherTNInflow?: FormControl<number>;
    DryWeatherTPInflow?: FormControl<number>;
    DryWeatherFCInflow?: FormControl<number>;
    DryWeatherTCuInflow?: FormControl<number>;
    DryWeatherTPbInflow?: FormControl<number>;
    DryWeatherTZnInflow?: FormControl<number>;
    TotalInflow?: FormControl<number>;
    TotalTreated?: FormControl<number>;
    TotalRetained?: FormControl<number>;
    TotalUntreated?: FormControl<number>;
    TotalTSSReduced?: FormControl<number>;
    TotalTNReduced?: FormControl<number>;
    TotalTPReduced?: FormControl<number>;
    TotalFCReduced?: FormControl<number>;
    TotalTCuReduced?: FormControl<number>;
    TotalTPbReduced?: FormControl<number>;
    TotalTZnReduced?: FormControl<number>;
    TotalTSSInflow?: FormControl<number>;
    TotalTNInflow?: FormControl<number>;
    TotalTPInflow?: FormControl<number>;
    TotalFCInflow?: FormControl<number>;
    TotalTCuInflow?: FormControl<number>;
    TotalTPbInflow?: FormControl<number>;
    TotalTZnInflow?: FormControl<number>;
}

export class ProjectLoadReducingResultDtoFormControls { 
    public static TreatmentBMPID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TreatmentBMPName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ProjectID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ProjectName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static EffectiveAreaAcres = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignStormDepth85thPercentile = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DesignVolume85thPercentile = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherRetained = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherUntreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTSSReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTNReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTPReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherFCReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTCuReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTPbReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTZnReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTSSInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTNInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTPInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherFCInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTCuInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTPbInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WetWeatherTZnInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherRetained = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherUntreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTSSReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTNReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTPReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherFCReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTCuReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTPbReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTZnReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTSSInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTNInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTPInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherFCInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTCuInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTPbInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SummerDryWeatherTZnInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherRetained = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherUntreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTSSReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTNReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTPReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherFCReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTCuReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTPbReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTZnReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTSSInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTNInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTPInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherFCInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTCuInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTPbInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WinterDryWeatherTZnInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherRetained = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherUntreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTSSReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTNReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTPReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherFCReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTCuReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTPbReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTZnReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTSSInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTNInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTPInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherFCInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTCuInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTPbInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DryWeatherTZnInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalRetained = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalUntreated = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTSSReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTNReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTPReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalFCReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTCuReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTPbReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTZnReduced = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTSSInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTNInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTPInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalFCInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTCuInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTPbInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalTZnInflow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
