//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FieldDefinitionType]

import { LookupTableEntry } from "src/app/shared/models/lookup-table-entry";
import { SelectDropdownOption } from "src/app/shared/components/inputs/select-dropdown/select-dropdown.component"

export enum FieldDefinitionTypeEnum {
  IsPrimaryContactOrganization = 1,
  Organization = 2,
  Password = 3,
  MeasurementUnit = 4,
  PhotoCaption = 5,
  PhotoCredit = 6,
  PhotoTiming = 7,
  PrimaryContact = 8,
  OrganizationType = 9,
  Username = 10,
  ExternalLinks = 11,
  RoleName = 12,
  ChartLastUpdatedDate = 13,
  TreatmentBMPType = 14,
  ConveyanceFunctionsAsIntended = 16,
  AssessmentScoreWeight = 17,
  ObservationScore = 18,
  AlternativeScore = 19,
  AssessmentForInternalUseOnly = 20,
  TreatmentBMPDesignDepth = 21,
  ReceivesSystemCommunications = 22,
  Jurisdiction = 23,
  Delineation = 24,
  TreatmentBMP = 25,
  TreatmentBMPAssessmentObservationType = 26,
  ObservationCollectionMethod = 27,
  ObservationThresholdType = 28,
  ObservationTargetType = 29,
  MeasurementUnitLabel = 30,
  PropertiesToObserve = 31,
  MinimumNumberOfObservations = 32,
  MaximumNumberOfObservations = 33,
  MinimumValueOfEachObservation = 34,
  MaximumValueOfEachObservation = 35,
  DefaultThresholdValue = 36,
  DefaultBenchmarkValue = 37,
  AssessmentFailsIfObservationFails = 38,
  CustomAttributeType = 39,
  CustomAttributeDataType = 40,
  MaintenanceRecordType = 41,
  MaintenanceRecord = 42,
  AttributeTypePurpose = 43,
  FundingSource = 44,
  IsPostMaintenanceAssessment = 45,
  FundingEvent = 46,
  FieldVisit = 47,
  FieldVisitStatus = 48,
  WaterQualityManagementPlan = 49,
  Parcel = 50,
  RequiredLifespanOfInstallation = 51,
  RequiredFieldVisitsPerYear = 52,
  RequiredPostStormFieldVisitsPerYear = 53,
  WaterQualityManagementPlanDocumentType = 54,
  HasAllRequiredDocuments = 55,
  DateOfLastInventoryChange = 56,
  TrashCaptureStatus = 57,
  OnlandVisualTrashAssessment = 58,
  OnlandVisualTrashAssessmentNotes = 59,
  DelineationType = 60,
  BaselineScore = 61,
  SizingBasis = 62,
  ProgressScore = 63,
  AssessmentScore = 64,
  ViaFullCapture = 65,
  ViaPartialCapture = 66,
  ViaOVTAScore = 67,
  TotalAchieved = 68,
  TargetLoadReduction = 69,
  TrashGenerationRate = 70,
  LandUse = 71,
  Area = 72,
  ImperviousArea = 73,
  GrossArea = 74,
  LandUseStatistics = 75,
  RegionalSubbasin = 76,
  AverageDivertedFlowrate = 77,
  AverageTreatmentFlowrate = 78,
  DesignDryWeatherTreatmentCapacity = 79,
  DesignLowFlowDiversionCapacity = 80,
  DesignMediaFiltrationRate = 81,
  DesignResidenceTimeForPermanentPool = 82,
  DiversionRate = 83,
  DrawdownTimeForWQDetentionVolume = 84,
  EffectiveFootprint = 85,
  EffectiveRetentionDepth = 86,
  InfiltrationDischargeRate = 87,
  InfiltrationSurfaceArea = 88,
  MediaBedFootprint = 89,
  MonthsOfOperationID = 90,
  PermanentPoolOrWetlandVolume = 91,
  RoutingConfiguration = 92,
  StorageVolumeBelowLowestOutletElevation = 93,
  SummerHarvestedWaterDemand = 94,
  TimeOfConcentrationID = 95,
  DrawdownTimeForDetentionVolume = 96,
  TotalEffectiveBMPVolume = 97,
  TotalEffectiveDrywellBMPVolume = 98,
  TreatmentRate = 99,
  UnderlyingHydrologicSoilGroupID = 100,
  UnderlyingInfiltrationRate = 101,
  UpstreamBMP = 102,
  WaterQualityDetentionVolume = 103,
  WettedFootprint = 104,
  WinterHarvestedWaterDemand = 105,
  PercentOfSiteTreated = 106,
  PercentCaptured = 107,
  PercentRetained = 108,
  AreaWithinWQMP = 109,
  Watershed = 110,
  DesignStormwaterDepth = 111,
  FullyParameterized = 112,
  HydromodificationApplies = 113,
  DelineationStatus = 114,
  DryWeatherFlowOverrideID = 115,
  ModeledPerformance = 116,
  OCTAM2Tier2GrantProgram = 117,
  SEAScore = 118,
  TPIScore = 119,
  WQLRI = 120,
  PollutantContributiontoSEA = 121,
  SiteRunoff = 122,
  TreatedAndDischarged = 123,
  RetainedOrRecycled = 124,
  UntreatedBypassOrOverflow = 125,
  TotalSuspendedSolids = 126,
  TotalNitrogen = 127,
  TotalPhosphorous = 128,
  FecalColiform = 129,
  TotalCopper = 130,
  TotalLead = 131,
  TotalZinc = 132,
  OCTAWatershed = 133,
  EffectiveAreaAcres = 134,
  DesignStormDepth85thPercentile = 135,
  DesignVolume85thPercentile = 136,
  LandUseBasedWaterQualityScore = 137,
  ReceivingWaterScore = 138,
  NumberOfIndividualBMPs = 139,
  LandUseType = 140,
  LandUseDescription = 141
}

export const FieldDefinitionTypes: LookupTableEntry[]  = [
  { Name: "IsPrimaryContactOrganization", DisplayName: "Is Primary Contact Organization", Value: 1 },
  { Name: "Organization", DisplayName: "Organization", Value: 2 },
  { Name: "Password", DisplayName: "Password", Value: 3 },
  { Name: "MeasurementUnit", DisplayName: "Measurement Unit", Value: 4 },
  { Name: "PhotoCaption", DisplayName: "Photo Caption", Value: 5 },
  { Name: "PhotoCredit", DisplayName: "Photo Credit", Value: 6 },
  { Name: "PhotoTiming", DisplayName: "Photo Timing", Value: 7 },
  { Name: "PrimaryContact", DisplayName: "Primary Contact", Value: 8 },
  { Name: "OrganizationType", DisplayName: "Organization Type", Value: 9 },
  { Name: "Username", DisplayName: "User name", Value: 10 },
  { Name: "ExternalLinks", DisplayName: "External Links", Value: 11 },
  { Name: "RoleName", DisplayName: "Role Name", Value: 12 },
  { Name: "Chart Last Updated Date", DisplayName: "Chart Last Updated Date", Value: 13 },
  { Name: "TreatmentBMPType", DisplayName: "Treatment BMP Type", Value: 14 },
  { Name: "ConveyanceFunctionsAsIntended", DisplayName: "Conveyance Functions as Intended", Value: 16 },
  { Name: "AssessmentScoreWeight", DisplayName: "Assessment Score Weight", Value: 17 },
  { Name: "ObservationScore", DisplayName: "Observation Score", Value: 18 },
  { Name: "AlternativeScore", DisplayName: "Alternative Score", Value: 19 },
  { Name: "AssessmentForInternalUseOnly", DisplayName: "Assessment for Internal Use Only", Value: 20 },
  { Name: "TreatmentBMPDesignDepth", DisplayName: "Treatment BMP Design Depth", Value: 21 },
  { Name: "ReceivesSystemCommunications", DisplayName: "Receives System Communications", Value: 22 },
  { Name: "Jurisdiction", DisplayName: "Jurisdiction", Value: 23 },
  { Name: "Delineation", DisplayName: "Delineation", Value: 24 },
  { Name: "TreatmentBMP", DisplayName: "Treatment BMP", Value: 25 },
  { Name: "TreatmentBMPAssessmentObservationType", DisplayName: "Observation Name", Value: 26 },
  { Name: "ObservationCollectionMethod", DisplayName: "Collection Method", Value: 27 },
  { Name: "ObservationThresholdType", DisplayName: "Threshold Type", Value: 28 },
  { Name: "ObservationTargetType", DisplayName: "Target Type", Value: 29 },
  { Name: "MeasurementUnitLabel", DisplayName: "Measurement Unit Label", Value: 30 },
  { Name: "PropertiesToObserve", DisplayName: "Properties To Observe", Value: 31 },
  { Name: "MinimumNumberOfObservations", DisplayName: "Minimum Number of Observations", Value: 32 },
  { Name: "MaximumNumberOfObservations", DisplayName: "Maximum Number of Observations", Value: 33 },
  { Name: "MinimumValueOfEachObservation", DisplayName: "Minimum Value of Each Observation", Value: 34 },
  { Name: "MaximumValueOfEachObservation", DisplayName: "Maximum Value of Each Observation", Value: 35 },
  { Name: "DefaultThresholdValue", DisplayName: "Default Threshold Value", Value: 36 },
  { Name: "DefaultBenchmarkValue", DisplayName: "Default Benchmark Value", Value: 37 },
  { Name: "AssessmentFailsIfObservationFails", DisplayName: "Assessment Fails if Observation Fails", Value: 38 },
  { Name: "CustomAttributeType", DisplayName: "Attribute Name", Value: 39 },
  { Name: "CustomAttributeDataType", DisplayName: "Data Type", Value: 40 },
  { Name: "MaintenanceRecordType", DisplayName: "Maintenance Type", Value: 41 },
  { Name: "MaintenanceRecord", DisplayName: "Maintenance Record", Value: 42 },
  { Name: "AttributeTypePurpose", DisplayName: "Purpose", Value: 43 },
  { Name: "FundingSource", DisplayName: "Funding Source", Value: 44 },
  { Name: "IsPostMaintenanceAssessment", DisplayName: "Post Maintenance Assessment?", Value: 45 },
  { Name: "FundingEvent", DisplayName: "Funding Event", Value: 46 },
  { Name: "FieldVisit", DisplayName: "Field Visit", Value: 47 },
  { Name: "FieldVisitStatus", DisplayName: "Field Visit Status", Value: 48 },
  { Name: "WaterQualityManagementPlan", DisplayName: "Water Quality Management Plan", Value: 49 },
  { Name: "Parcel", DisplayName: "Parcel", Value: 50 },
  { Name: "RequiredLifespanOfInstallation", DisplayName: "Required Lifespan of Installation", Value: 51 },
  { Name: "RequiredFieldVisitsPerYear", DisplayName: "Required Field Visits Per Year", Value: 52 },
  { Name: "RequiredPostStormFieldVisitsPerYear", DisplayName: "Required Post-Storm Field Visits Per Year", Value: 53 },
  { Name: "WaterQualityManagementPlanDocumentType", DisplayName: "WQMP Document Type", Value: 54 },
  { Name: "HasAllRequiredDocuments", DisplayName: "Has All Required Documents?", Value: 55 },
  { Name: "DateOfLastInventoryChange", DisplayName: "Date of Last Inventory Change", Value: 56 },
  { Name: "TrashCaptureStatus", DisplayName: "Trash Capture Status", Value: 57 },
  { Name: "OnlandVisualTrashAssessment", DisplayName: "On-land Visual Trash Assessment", Value: 58 },
  { Name: "OnlandVisualTrashAssessmentNotes", DisplayName: "Comments and Additional Information", Value: 59 },
  { Name: "DelineationType", DisplayName: "Delineation Type", Value: 60 },
  { Name: "BaselineScore", DisplayName: "Baseline Score", Value: 61 },
  { Name: "SizingBasis", DisplayName: "Sizing Basis", Value: 62 },
  { Name: "ProgressScore", DisplayName: "Progress Score", Value: 63 },
  { Name: "AssessmentScore", DisplayName: "Assessment Score", Value: 64 },
  { Name: "ViaFullCapture", DisplayName: "Via Full Capture", Value: 65 },
  { Name: "ViaPartialCapture", DisplayName: "Via Partial Capture", Value: 66 },
  { Name: "ViaOVTAScore", DisplayName: "Via OVTA Score", Value: 67 },
  { Name: "TotalAchieved", DisplayName: "Total Achieved", Value: 68 },
  { Name: "TargetLoadReduction", DisplayName: "Target Load Reduction", Value: 69 },
  { Name: "TrashGenerationRate", DisplayName: "Trash Generation Rate", Value: 70 },
  { Name: "LandUse", DisplayName: "Land Use", Value: 71 },
  { Name: "Area", DisplayName: "Area", Value: 72 },
  { Name: "ImperviousArea", DisplayName: "Impervious Area", Value: 73 },
  { Name: "GrossArea", DisplayName: "Gross Area", Value: 74 },
  { Name: "LandUseStatistics", DisplayName: "Land Use Statistics", Value: 75 },
  { Name: "RegionalSubbasin", DisplayName: "Regional Subbasin", Value: 76 },
  { Name: "AverageDivertedFlowrate", DisplayName: "Average Diverted Flowrate", Value: 77 },
  { Name: "AverageTreatmentFlowrate", DisplayName: "Average Treatment Flowrate", Value: 78 },
  { Name: "DesignDryWeatherTreatmentCapacity", DisplayName: "Design Dry Weather Treatment Capacity", Value: 79 },
  { Name: "DesignLowFlowDiversionCapacity", DisplayName: "Design Low Flow Diversion Capacity", Value: 80 },
  { Name: "DesignMediaFiltrationRate", DisplayName: "Design Media Filtration Rate", Value: 81 },
  { Name: "DesignResidenceTimeForPermanentPool", DisplayName: "Design Residence Time for Permanent Pool", Value: 82 },
  { Name: "DiversionRate", DisplayName: "Diversion Rate", Value: 83 },
  { Name: "DrawdownTimeForWQDetentionVolume", DisplayName: "Drawdown Time for WQ Detention Volume", Value: 84 },
  { Name: "EffectiveFootprint", DisplayName: "Effective Footprint", Value: 85 },
  { Name: "EffectiveRetentionDepth", DisplayName: "Effective Retention Depth", Value: 86 },
  { Name: "InfiltrationDischargeRate", DisplayName: "Infiltration Discharge Rate", Value: 87 },
  { Name: "InfiltrationSurfaceArea", DisplayName: "Infiltration Surface Area", Value: 88 },
  { Name: "MediaBedFootprint", DisplayName: "Media Bed Footprint", Value: 89 },
  { Name: "MonthsOfOperationID", DisplayName: "Months Operational", Value: 90 },
  { Name: "PermanentPoolOrWetlandVolume", DisplayName: "Permanent Pool or Wetland Volume", Value: 91 },
  { Name: "RoutingConfiguration", DisplayName: "Routing Configuration", Value: 92 },
  { Name: "StorageVolumeBelowLowestOutletElevation", DisplayName: "Storage Volume Below Lowest Outlet Elevation", Value: 93 },
  { Name: "SummerHarvestedWaterDemand", DisplayName: "Summer Harvested Water Demand", Value: 94 },
  { Name: "TimeOfConcentrationID", DisplayName: "Time of Concentration", Value: 95 },
  { Name: "DrawdownTimeForDetentionVolume", DisplayName: "Drawdown Time For Detention Volume", Value: 96 },
  { Name: "TotalEffectiveBMPVolume", DisplayName: "Total Effective BMP Volume", Value: 97 },
  { Name: "TotalEffectiveDrywellBMPVolume", DisplayName: "Total Effective Drywell BMP Volume", Value: 98 },
  { Name: "TreatmentRate", DisplayName: "Treatment Rate", Value: 99 },
  { Name: "UnderlyingHydrologicSoilGroupID", DisplayName: "Underlying Hydrologic Soil Group (HSG)", Value: 100 },
  { Name: "UnderlyingInfiltrationRate", DisplayName: "Underlying Infiltration Rate", Value: 101 },
  { Name: "UpstreamBMP", DisplayName: "Upstream BMP", Value: 102 },
  { Name: "WaterQualityDetentionVolume", DisplayName: "Extended Detention Surcharge Volume", Value: 103 },
  { Name: "WettedFootprint", DisplayName: "Wetted Footprint", Value: 104 },
  { Name: "WinterHarvestedWaterDemand", DisplayName: "Winter Harvested Water Demand", Value: 105 },
  { Name: "PercentOfSiteTreated", DisplayName: "% of Site Treated", Value: 106 },
  { Name: "PercentCaptured", DisplayName: "Wet Weather % Captured", Value: 107 },
  { Name: "PercentRetained", DisplayName: "Wet Weather % Retained", Value: 108 },
  { Name: "AreaWithinWQMP", DisplayName: "Area within WQMP", Value: 109 },
  { Name: "Watershed", DisplayName: "Watershed", Value: 110 },
  { Name: "DesignStormwaterDepth", DisplayName: "Design Stormwater Depth", Value: 111 },
  { Name: "FullyParameterized", DisplayName: "Fully Parameterized?", Value: 112 },
  { Name: "HydromodificationApplies", DisplayName: "Hydromodification Controls Apply", Value: 113 },
  { Name: "DelineationStatus", DisplayName: "Delineation Status", Value: 114 },
  { Name: "DryWeatherFlowOverrideID", DisplayName: "Dry Weather Flow Override?", Value: 115 },
  { Name: "ModeledPerformance", DisplayName: "Modeled Performance", Value: 116 },
  { Name: "OCTA M2 Tier 2 Grant Program", DisplayName: "OCTA M2 Tier 2 Grant Program", Value: 117 },
  { Name: "SEA Score", DisplayName: "Strategically Effective Area Score", Value: 118 },
  { Name: "TPI Score", DisplayName: "Transportation Nexus Score", Value: 119 },
  { Name: "WQLRI", DisplayName: "Water Quality Load Reduction Index", Value: 120 },
  { Name: "Pollutant Contribution to SEA", DisplayName: "Pollutant Contribution to Strategically Effective Area", Value: 121 },
  { Name: "SiteRunoff", DisplayName: "Site Runoff", Value: 122 },
  { Name: "TreatedAndDischarged", DisplayName: "Treated and Discharged", Value: 123 },
  { Name: "RetainedOrRecycled", DisplayName: "Retained or Recycled", Value: 124 },
  { Name: "Untreated(BypassOrOverflow)", DisplayName: "Untreated (Bypass or Overflow)", Value: 125 },
  { Name: "TotalSuspendedSolids", DisplayName: "Total Suspended Solids", Value: 126 },
  { Name: "TotalNitrogen", DisplayName: "Total Nitrogen", Value: 127 },
  { Name: "TotalPhosphorous", DisplayName: "Total Phosphorous", Value: 128 },
  { Name: "FecalColiform", DisplayName: "Fecal Coliform", Value: 129 },
  { Name: "TotalCopper", DisplayName: "Total Copper", Value: 130 },
  { Name: "TotalLead", DisplayName: "Total Lead", Value: 131 },
  { Name: "TotalZinc", DisplayName: "Total Zinc", Value: 132 },
  { Name: "OCTAWatershed", DisplayName: "OCTA Watershed", Value: 133 },
  { Name: "EffectiveAreaAcres", DisplayName: "Effective Area (acres)", Value: 134 },
  { Name: "DesignStormDepth85thPercentile", DisplayName: "85th Percentile Design Storm Depth (inches)", Value: 135 },
  { Name: "DesignVolume85thPercentile", DisplayName: "85th Percentile Design Volume (cuft)", Value: 136 },
  { Name: "LandUseBasedWaterQualityScore", DisplayName: "Land Use Based Water Quality Score", Value: 137 },
  { Name: "ReceivingWaterScore", DisplayName: "Receiving Water Score", Value: 138 },
  { Name: "NumberOfIndividualBMPs", DisplayName: "# of Individual BMPs", Value: 139 },
  { Name: "LandUseType", DisplayName: "Land Use Type", Value: 140 },
  { Name: "LandUseDescription", DisplayName: "Land Use Description", Value: 141 }
];
export const FieldDefinitionTypesAsSelectDropdownOptions = [{ Value: null, Label: "- Select -", Disabled: true }, ...FieldDefinitionTypes.map((x) => ({ Value: x.Value, Label: x.DisplayName } as SelectDropdownOption))];
