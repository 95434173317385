<section class="hero">I am trashy.</section>
<app-alert-display></app-alert-display>
<h1 class="text-primary mt-4 mb-4">OCST Trash Module</h1>
<ng-container *ngIf="currentUser$ | async as currentUser">
    <div *ngIf="userIsUnassigned(currentUser)" class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            <span *ngIf="userIsOCTAGrantReviewer(currentUser); else unassignedUserMessage">
                Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, and an administrator has granted you
                read-only access to Review Grant Programs. You may <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or
                comments.
            </span>
            <ng-template #unassignedUserMessage>
                <span>
                    Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, but your account is not yet
                    configured. You will receive an email from the Administrators when your account is ready to use. You may
                    <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or comments.
                </span>
            </ng-template>
        </div>
    </div>
</ng-container>

<custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
