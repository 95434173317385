//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[NeptunePageType]

import { LookupTableEntry } from "src/app/shared/models/lookup-table-entry";
import { SelectDropdownOption } from "src/app/shared/components/inputs/select-dropdown/select-dropdown.component"

export enum NeptunePageTypeEnum {
  HomePage = 1,
  About = 2,
  OrganizationsList = 3,
  HomeMapInfo = 4,
  HomeAdditionalInfo = 5,
  TreatmentBMP = 6,
  TreatmentBMPType = 7,
  Jurisdiction = 9,
  Assessment = 10,
  ManageObservationTypesList = 11,
  ManageTreatmentBMPTypesList = 12,
  ManageObservationTypeInstructions = 13,
  ManageObservationTypeObservationInstructions = 14,
  ManageObservationTypeLabelsAndUnitsInstructions = 15,
  ManageTreatmentBMPTypeInstructions = 16,
  ManageCustomAttributeTypeInstructions = 17,
  ManageCustomAttributeInstructions = 18,
  ManageCustomAttributeTypesList = 19,
  Legal = 20,
  FundingSourcesList = 21,
  FindABMP = 22,
  LaunchPad = 23,
  FieldRecords = 24,
  RequestSupport = 25,
  InviteUser = 26,
  WaterQualityMaintenancePlan = 27,
  ParcelList = 28,
  Training = 29,
  ManagerDashboard = 30,
  WaterQualityMaintenancePlanOandMVerifications = 31,
  ModelingHomePage = 32,
  TrashHomePage = 33,
  OVTAInstructions = 34,
  OVTAIndex = 35,
  TrashModuleProgramOverview = 36,
  DelineationMap = 37,
  BulkUploadRequest = 38,
  TreatmentBMPAssessment = 41,
  EditOVTAArea = 42,
  LandUseBlock = 43,
  ExportAssessmentGeospatialData = 44,
  HRUCharacteristics = 45,
  RegionalSubbasins = 46,
  DelineationReconciliationReport = 47,
  ViewTreatmentBMPModelingAttributes = 48,
  UploadTreatmentBMPs = 49,
  AboutModelingBMPPerformance = 50,
  BulkUploadFieldVisits = 51,
  HippocampHomePage = 52,
  HippocampTraining = 53,
  HippocampLabelsAndDefinitionsList = 54,
  HippocampAbout = 55,
  HippocampProjectsList = 56,
  HippocampProjectInstructions = 57,
  HippocampProjectBasics = 58,
  HippocampProjectAttachments = 59,
  HippocampTreatmentBMPs = 60,
  HippocampDelineations = 61,
  HippocampModeledPerformance = 62,
  HippocampReview = 63,
  HippocampPlanningMap = 64,
  OCTAM2Tier2GrantProgramMetrics = 65,
  OCTAM2Tier2GrantProgramDashboard = 66,
  EditWQMPBoundary = 67,
  UploadWQMPs = 68,
  UploadSimplifiedBMPs = 69,
  UploadOVTAs = 70,
  WQMPBoundaryFromAPNList = 71,
  BMPDataHub = 72,
  DelineationDataHub = 73,
  FieldVisitDataHub = 74,
  WQMPDataHub = 75,
  SimplifiedBMPsDataHub = 76,
  WQMPLocationsDataHub = 77,
  AssessmentAreasDataHub = 78,
  OVTADataHub = 79,
  LandUseBlockDataHub = 80,
  RegionalSubbasinsDataHub = 81,
  LandUseStatisticsDataHub = 82,
  ModelBasinsDataHub = 83,
  PrecipitationZonesDataHub = 84,
  ParcelUploadDataHub = 85,
  ExportBMPInventoryToGIS = 86
}

export const NeptunePageTypes: LookupTableEntry[]  = [
  { Name: "HomePage", DisplayName: "Home Page", Value: 1 },
  { Name: "About", DisplayName: "About", Value: 2 },
  { Name: "OrganizationsList", DisplayName: "Organizations List", Value: 3 },
  { Name: "HomeMapInfo", DisplayName: "Home Page Map Info", Value: 4 },
  { Name: "HomeAdditionalInfo", DisplayName: "Home Page Additional Info", Value: 5 },
  { Name: "TreatmentBMP", DisplayName: "Treatment BMP", Value: 6 },
  { Name: "TreatmentBMPType", DisplayName: "Treatment BMP Type", Value: 7 },
  { Name: "Jurisdiction", DisplayName: "Jurisdiction", Value: 9 },
  { Name: "Assessment", DisplayName: "Assessment", Value: 10 },
  { Name: "ManageObservationTypesList", DisplayName: "Manage Observation Types List", Value: 11 },
  { Name: "ManageTreatmentBMPTypesList", DisplayName: "Manage Treatment BMP Types List", Value: 12 },
  { Name: "ManageObservationTypeInstructions", DisplayName: "Manage Observation Type Instructions", Value: 13 },
  { Name: "ManageObservationTypeObservationInstructions", DisplayName: "Manage Observation Type Instructions for Observation Instructions", Value: 14 },
  { Name: "ManageObservationTypeLabelsAndUnitsInstructions", DisplayName: "Manage Observation Type Labels and Units Instructions", Value: 15 },
  { Name: "ManageTreatmentBMPTypeInstructions", DisplayName: "Manage Treatment BMP Type Instructions", Value: 16 },
  { Name: "ManageCustomAttributeTypeInstructions", DisplayName: "Manage Custom Attribute Type Instructions", Value: 17 },
  { Name: "ManageCustomAttributeInstructions", DisplayName: "Manage Custom Attribute Instructions", Value: 18 },
  { Name: "ManageCustomAttributeTypesList", DisplayName: "Manage Custom Attribute Types List", Value: 19 },
  { Name: "Legal", DisplayName: "Legal", Value: 20 },
  { Name: "FundingSourcesList", DisplayName: "Funding Sources List", Value: 21 },
  { Name: "FindABMP", DisplayName: "Find a BMP", Value: 22 },
  { Name: "LaunchPad", DisplayName: "Launch Pad", Value: 23 },
  { Name: "FieldRecords", DisplayName: "Field Records", Value: 24 },
  { Name: "RequestSupport", DisplayName: "Request Support", Value: 25 },
  { Name: "InviteUser", DisplayName: "Invite User", Value: 26 },
  { Name: "WaterQualityMaintenancePlan", DisplayName: "Water Quality Maintenance Plan", Value: 27 },
  { Name: "ParcelList", DisplayName: "Parcel List", Value: 28 },
  { Name: "Training", DisplayName: "Training", Value: 29 },
  { Name: "ManagerDashboard", DisplayName: "Manager Dashboard", Value: 30 },
  { Name: "WaterQualityMaintenancePlanOandMVerifications", DisplayName: "Water Quality Maintenance Plan O&M Verifications", Value: 31 },
  { Name: "ModelingHomePage", DisplayName: "Modeling Module", Value: 32 },
  { Name: "TrashHomePage", DisplayName: "Trash Module Home Page", Value: 33 },
  { Name: "OVTAInstructions", DisplayName: "OVTA Instructions", Value: 34 },
  { Name: "OVTAIndex", DisplayName: "OVTA Index", Value: 35 },
  { Name: "TrashModuleProgramOverview", DisplayName: "Trash Module Program Overview", Value: 36 },
  { Name: "DelineationMap", DisplayName: "Delineation Map", Value: 37 },
  { Name: "BulkUploadRequest", DisplayName: "Bulk Upload Request", Value: 38 },
  { Name: "TreatmentBMPAssessment", DisplayName: "Treatment BMP Assessment", Value: 41 },
  { Name: "EditOVTAArea", DisplayName: "Edit OVTA Area", Value: 42 },
  { Name: "LandUseBlock", DisplayName: "Land Use Block", Value: 43 },
  { Name: "ExportAssessmentGeospatialData", DisplayName: "Export Assessment Geospatial Data", Value: 44 },
  { Name: "HRUCharacteristics", DisplayName: "HRU Characteristics", Value: 45 },
  { Name: "RegionalSubbasins", DisplayName: "Regional Subbasins", Value: 46 },
  { Name: "DelineationReconciliationReport", DisplayName: "Delineation Reconciliation Report", Value: 47 },
  { Name: "ViewTreatmentBMPModelingAttributes", DisplayName: "View Treatment BMP Modeling Attributes", Value: 48 },
  { Name: "UploadTreatmentBMPs", DisplayName: "Upload Treatment BMPs", Value: 49 },
  { Name: "AboutModelingBMPPerformance", DisplayName: "About Modeling BMP Performance", Value: 50 },
  { Name: "BulkUploadFieldVisits", DisplayName: "Bulk Upload Field Visits", Value: 51 },
  { Name: "HippocampHomePage", DisplayName: "Hippocamp Home Page", Value: 52 },
  { Name: "HippocampTraining", DisplayName: "Hippocamp Training", Value: 53 },
  { Name: "HippocampLabelsAndDefinitionsList", DisplayName: "Hippocamp Labels and Definitions List", Value: 54 },
  { Name: "HippocampAbout", DisplayName: "Hippocamp About Page", Value: 55 },
  { Name: "HippocampProjectsList", DisplayName: "Hippocamp Projects List", Value: 56 },
  { Name: "HippocampProjectInstructions", DisplayName: "Hippocamp Project Instructions Page", Value: 57 },
  { Name: "HippocampProjectBasics", DisplayName: "Hippocamp Project Basics", Value: 58 },
  { Name: "HippocampProjectAttachments", DisplayName: "Hippocamp Project Attachments", Value: 59 },
  { Name: "HippocampTreatmentBMPs", DisplayName: "Hippocamp Treatment BMPs", Value: 60 },
  { Name: "HippocampDelineations", DisplayName: "Hippocamp Delineations", Value: 61 },
  { Name: "HippocampModeledPerformance", DisplayName: "Hippocamp Modeled Performance", Value: 62 },
  { Name: "HippocampReview", DisplayName: "Hippocamp Review", Value: 63 },
  { Name: "HippocampPlanningMap", DisplayName: "Hippocamp Planning Map", Value: 64 },
  { Name: "OCTAM2Tier2GrantProgramMetrics", DisplayName: "OCTA M2 Tier 2 Grant Program Metrics", Value: 65 },
  { Name: "OCTAM2Tier2GrantProgramDashboard", DisplayName: "OCTA M2 Tier 2 Grant Program Dashboard", Value: 66 },
  { Name: "EditWQMPBoundary", DisplayName: "Refine WQMP Boundary Area", Value: 67 },
  { Name: "UploadWQMPs", DisplayName: "Bulk Upload Water Quality Management Plans", Value: 68 },
  { Name: "UploadSimplifiedBMPs", DisplayName: "Bulk Upload Simplified BMPs", Value: 69 },
  { Name: "UploadOVTAs", DisplayName: "Bulk Upload OVTAs", Value: 70 },
  { Name: "WQMPBoundaryFromAPNList", DisplayName: "WQMP Boundary from APN List", Value: 71 },
  { Name: "BMPDataHub", DisplayName: "Treatment BMP Data Hub", Value: 72 },
  { Name: "DelineationDataHub", DisplayName: "Delineation Data Hub", Value: 73 },
  { Name: "FieldVisitDataHub", DisplayName: "Field Visit Data Hub", Value: 74 },
  { Name: "WQMPDataHub", DisplayName: "WQMP Data Hub", Value: 75 },
  { Name: "SimplifiedBMPsDataHub", DisplayName: "Simplified BMPs Data Hub", Value: 76 },
  { Name: "WQMPLocationsDataHub", DisplayName: "WQMP Locations Data Hub", Value: 77 },
  { Name: "AssessmentAreasDataHub", DisplayName: "Assessment Areas Data Hub", Value: 78 },
  { Name: "OVTADataHub", DisplayName: "OVTA Data Hub", Value: 79 },
  { Name: "LandUseBlockDataHub", DisplayName: "Land Use Block Data Hub", Value: 80 },
  { Name: "RegionalSubbasinsDataHub", DisplayName: "Regional Subbasins Data Hub", Value: 81 },
  { Name: "LandUseStatisticsDataHub", DisplayName: "Land Use Statistics Data Hub", Value: 82 },
  { Name: "ModelBasinsDataHub", DisplayName: "Model Basins Data Hub", Value: 83 },
  { Name: "PrecipitationZonesDataHub", DisplayName: "Precipitation Zones Data Hub", Value: 84 },
  { Name: "ParcelUploadDataHub", DisplayName: "Parcel Upload Data Hub", Value: 85 },
  { Name: "ExportBMPInventoryToGIS", DisplayName: "Export BMP Inventory to GIS", Value: 86 }
];
export const NeptunePageTypesAsSelectDropdownOptions = [{ Value: null, Label: "- Select -", Disabled: true }, ...NeptunePageTypes.map((x) => ({ Value: x.Value, Label: x.DisplayName } as SelectDropdownOption))];
