<ng-container *ngIf="selectedProjectLoadReducingResult">
    <div *ngIf="getNotFullyParameterizedBMPNames().length > 0" class="alert alert-warning">
        <div class="alert-content">
            <i class="fa fa-warning"></i>
            Some of the proposed BMPs (see list below) are not fully parameterized for modeling, or have missing delineations, so these results may be incomplete or inaccurate.
            <ul>
                <li *ngFor="let name of getNotFullyParameterizedBMPNames()">{{ name }}</li>
            </ul>
        </div>
    </div>
    <div *ngIf="getBMPNamesForDelineationsWithDiscrepancies().length > 0" class="alert alert-warning">
        <div class="alert-content">
            <i class="fa fa-warning"></i>
            <div>
                <p>
                    Some of the proposed BMPs (see list below) have delineations that overlap an existing BMP delineation, another delineation within this project, or more than one
                    regional sub-basin. Results for these BMPs will be inaccurate for the overlapping area.
                </p>
                <ul class="mt-2">
                    <li *ngFor="let name of getBMPNamesForDelineationsWithDiscrepancies()">{{ name }}</li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="isMostRecentHistoryOfType(ProjectNetworkHistoryStatusTypeEnum.Queued)" class="alert alert-warning">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            Results for this project are currently being calculated. The below results will remain until the current calculation completes and then will be discarded in favor of
            the newer results.
        </div>
    </div>

    <select class="form-control mb-3" [(ngModel)]="treatmentBMPIDForSelectedProjectLoadReducingResult" (change)="updateSelectedProjectLoadReducingResult()">
        <option *ngFor="let selectListOption of modelingSelectListOptions" [value]="selectListOption.TreatmentBMPID">{{ selectListOption.TreatmentBMPName }}</option>
    </select>

    <div class="grid-12">
        <div class="g-col-6">
            <btn-group-radio-input [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab" [showIcons]="true"></btn-group-radio-input>
            <div [hidden]="activeTab !== 'Wet'">
                <table class="table table-responsive table-striped table-condensed modelResults">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Water Balance Components</th>
                            <th>Volume (cu-ft/yr)</th>
                            <th>Percent of Inflow</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="treatmentBMPIDForSelectedProjectLoadReducingResult != 0">
                            <td class="left">
                                <field-definition fieldDefinitionType="SiteRunoff" labelOverride="To BMP"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherInflow | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>--</td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TreatedAndDischarged"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTreated) / selectedProjectLoadReducingResult.WetWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="RetainedOrRecycled"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherRetained | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherRetained) / selectedProjectLoadReducingResult.WetWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="UntreatedBypassOrOverflow"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherUntreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherUntreated) / selectedProjectLoadReducingResult.WetWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-responsive table-striped table-condensed modelResults mt-4">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Pollutant</th>
                            <th>Load Reduced</th>
                            <th>Percent Reduced</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalSuspendedSolids"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTSSReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTSSReduced) / selectedProjectLoadReducingResult.WetWeatherTSSInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalNitrogen"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTNReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTNReduced) / selectedProjectLoadReducingResult.WetWeatherTNInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalPhosphorous"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTPReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTPReduced) / selectedProjectLoadReducingResult.WetWeatherTPInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="FecalColiform"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherFCReduced | neptuneModelingResultSigFig : 2 | number }}</span> billion CFUs
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherFCReduced) / selectedProjectLoadReducingResult.WetWeatherFCInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalCopper"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTCuReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTCuReduced) / selectedProjectLoadReducingResult.WetWeatherTCuInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalLead"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTPbReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTPbReduced) / selectedProjectLoadReducingResult.WetWeatherTPbInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalZinc"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.WetWeatherTZnReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.WetWeatherTZnReduced) / selectedProjectLoadReducingResult.WetWeatherTZnInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [hidden]="activeTab !== 'Dry'">
                <table class="table table-responsive table-striped table-condensed modelResults">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Water Balance Components</th>
                            <th>Volume (cu-ft/yr)</th>
                            <th>Percent of Inflow</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="treatmentBMPIDForSelectedProjectLoadReducingResult != 0">
                            <td class="left">
                                <field-definition fieldDefinitionType="SiteRunoff" labelOverride="To BMP"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherInflow | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>--</td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TreatedAndDischarged"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTreated) / selectedProjectLoadReducingResult.DryWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="RetainedOrRecycled"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherRetained | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherRetained) / selectedProjectLoadReducingResult.DryWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="UntreatedBypassOrOverflow"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherUntreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherUntreated) / selectedProjectLoadReducingResult.DryWeatherInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-responsive table-striped table-condensed modelResults mt-4">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Pollutant</th>
                            <th>Load Reduced</th>
                            <th>Percent Reduced</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalSuspendedSolids"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTSSReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTSSReduced) / selectedProjectLoadReducingResult.DryWeatherTSSInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalNitrogen"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTNReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTNReduced) / selectedProjectLoadReducingResult.DryWeatherTNInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalPhosphorous"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTPReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTPReduced) / selectedProjectLoadReducingResult.DryWeatherTPInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="FecalColiform"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherFCReduced | neptuneModelingResultSigFig : 2 | number }}</span> billion CFUs
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherFCReduced) / selectedProjectLoadReducingResult.DryWeatherFCInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalCopper"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTCuReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTCuReduced) / selectedProjectLoadReducingResult.DryWeatherTCuInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalLead"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTPbReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTPbReduced) / selectedProjectLoadReducingResult.DryWeatherTPbInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalZinc"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.DryWeatherTZnReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.DryWeatherTZnReduced) / selectedProjectLoadReducingResult.DryWeatherTZnInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [hidden]="activeTab !== 'Total'">
                <table class="table table-responsive table-striped table-condensed modelResults" *ngIf="activeID === ModeledPerformanceDisplayTypeEnum.Total">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Water Balance Components</th>
                            <th>Volume (cu-ft/yr)</th>
                            <th>Percent of Inflow</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="treatmentBMPIDForSelectedProjectLoadReducingResult != 0">
                            <td class="left">
                                <field-definition fieldDefinitionType="SiteRunoff" labelOverride="To BMP"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalInflow | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>--</td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TreatedAndDischarged"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalTreated) / selectedProjectLoadReducingResult.TotalInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="RetainedOrRecycled"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalRetained | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalRetained) / selectedProjectLoadReducingResult.TotalInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="UntreatedBypassOrOverflow"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalUntreated | neptuneModelingResultSigFig : 3 | number }}</span>
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalUntreated) / selectedProjectLoadReducingResult.TotalInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-responsive table-striped table-condensed modelResults mt-4">
                    <thead>
                        <tr style="text-align: center">
                            <th class="left">Pollutant</th>
                            <th>Load Reduced</th>
                            <th>Percent Reduced</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalSuspendedSolids"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTSSReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.TotalTSSReduced) / selectedProjectLoadReducingResult.TotalTSSInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalNitrogen"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTNReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalTNReduced) / selectedProjectLoadReducingResult.TotalTNInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalPhosphorous"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTPReduced | neptuneModelingResultSigFig : 2 | number }}</span> kg
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalTPReduced) / selectedProjectLoadReducingResult.TotalTPInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="FecalColiform"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalFCReduced | neptuneModelingResultSigFig : 2 | number }}</span> billion CFUs
                            </td>
                            <td>
                                <span>{{ (100 * selectedProjectLoadReducingResult.TotalFCReduced) / selectedProjectLoadReducingResult.TotalFCInflow || 0 | number : "1.0-0" }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalCopper"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTCuReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.TotalTCuReduced) / selectedProjectLoadReducingResult.TotalTCuInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalLead"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTPbReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.TotalTPbReduced) / selectedProjectLoadReducingResult.TotalTPbInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                        <tr>
                            <td class="left">
                                <field-definition fieldDefinitionType="TotalZinc"></field-definition>
                            </td>
                            <td>
                                <span>{{ selectedProjectLoadReducingResult.TotalTZnReduced | neptuneModelingResultSigFig : 2 | number }}</span> g
                            </td>
                            <td>
                                <span>{{
                                    (100 * selectedProjectLoadReducingResult.TotalTZnReduced) / selectedProjectLoadReducingResult.TotalTZnInflow || 0 | number : "1.0-0"
                                }}</span
                                >%
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="g-col-6">
            <table id="landUseTable" class="table table-striped" style="text-align: center">
                <thead>
                    <tr>
                        <th style="text-align: left; min-width: 270px">
                            <field-definition fieldDefinitionType="LandUse"></field-definition>
                        </th>
                        <th style="text-align: center">
                            <field-definition fieldDefinitionType="Area" [labelOverride]="'Area (ac)'"> </field-definition>
                        </th>
                        <th style="text-align: center">
                            <field-definition fieldDefinitionType="ImperviousArea" [labelOverride]="'Impervious Area (ac)'"> </field-definition>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let hruCharacteristicSummary of selectedTreatmentBMPHRUCharacteristicSummaries">
                        <td style="text-align: left">{{ hruCharacteristicSummary.LandUse }}</td>
                        <td>{{ hruCharacteristicSummary.Area | number : "1.2-2" }}</td>
                        <td>{{ hruCharacteristicSummary.ImperviousCover | number : "1.2-2" }}</td>
                    </tr>
                    <tr style="font-weight: bold">
                        <td style="text-align: left">
                            {{ selectedTreatmentBMPHRUCharacteristicSummaryTotal.LandUse }}
                        </td>
                        <td>{{ selectedTreatmentBMPHRUCharacteristicSummaryTotal.Area | number : "1.2-2" }}</td>
                        <td>{{ selectedTreatmentBMPHRUCharacteristicSummaryTotal.ImperviousCover | number : "1.2-2" }}</td>
                    </tr>
                </tbody>
            </table>

            <table id="designSizingInfoPanel" class="table table-striped mt-4" *ngIf="treatmentBMPIDForSelectedProjectLoadReducingResult > 0">
                <thead>
                    <tr>
                        <th colspan="2">Design Sizing Info</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <field-definition fieldDefinitionType="EffectiveAreaAcres"></field-definition>
                        </td>
                        <td style="text-align: left">
                            {{ selectedProjectLoadReducingResult.EffectiveAreaAcres | number : "1.2-2" }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <field-definition fieldDefinitionType="DesignStormDepth85thPercentile"> </field-definition>
                        </td>
                        <td>{{ selectedProjectLoadReducingResult.DesignStormDepth85thPercentile | number : "1.2-2" }}</td>
                    </tr>
                    <tr>
                        <td>
                            <field-definition fieldDefinitionType="DesignVolume85thPercentile"> </field-definition>
                        </td>
                        <td>{{ selectedProjectLoadReducingResult.DesignVolume85thPercentile | number : "1.2-2" }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <p class="text-italic mt-2">{{ getModelResultsLastCalculatedText() }}</p>
</ng-container>
